import "../index.css";
import React from 'react';
import ReactPlayer from 'react-player';

let videosrc = "/videos/Auferstehung-Jesu.mp4";

export default props => {
  return (
    <div class="video-container">
      <span class="video-header">Die Auferstehung Jesu</span>
      <ReactPlayer
          className='react-player fixed-center'
          url={videosrc}
          width='80%'
          height='60%'
          controls = {true}
          />
    </div>
    
  );
};








  /**<Player
        playsInline
        poster="../public/assets/image/logo192.png"
        src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
        width="100vw"
        height="80vh"
      /> */