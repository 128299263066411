import React, { Component } from 'react';
import Navbar from './navbar';
import VideoPlayer from './VideoPlayer';


class App extends Component {
    state = {  } 
    render() { 
        return <React.Fragment>
            {/* <Navbar><h1>Videos rund um die Bibel 0% Theologie</h1></Navbar> */}
            <VideoPlayer />
        </React.Fragment>;
    }
}
 
export default App;